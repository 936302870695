import { saveAs } from 'file-saver';
import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { useMemo, useState } from 'react';
import { useClient, useQuery } from 'urql';

import {
  Card,
  CardBody,
  DateField,
  Form,
  SelectField,
  SelectOption,
  SubmitButton,
} from '@/components';
import { useTranslate } from '@/contexts';
import { BrandEnum } from '@/globalTypes';
import { Nullable } from '@/types';
import {
  CashbackReport,
  CashbackReportVariables,
} from './__generated__/CashbackReport';
import { CashbackReportBox } from './__generated__/CashbackReportBox';
import { SanityCashbackReportBlockStaticQuery } from './__generated__/SanityCashbackReportBlockStaticQuery';

const BLOCK_STATIC_QUERY = graphql`
  query SanityCashbackReportBlockStaticQuery {
    sanityCashbackReportBlock {
      title {
        ...LocaleString
      }
      selectBrand {
        ...LocaleString
      }
      brandLabel {
        ...LocaleString
      }
      fromLabel {
        ...LocaleString
      }
      toLabel {
        ...LocaleString
      }
      exportCashback {
        ...LocaleString
      }
    }
  }
`;

const QUERY = gql`
  query CashbackReportBox {
    viewer {
      id
      brands {
        code
        name
      }
    }
  }
`;

const REPORT_QUERY = gql`
  query CashbackReport(
    $brand: BrandEnum!
    $from: OffsetDateTime
    $to: OffsetDateTime
  ) {
    viewer {
      id
      exportedCashbackReportV4(brand: $brand, from: $from, to: $to) {
        url
      }
    }
  }
`;

type FormValues = {
  brand: BrandEnum | '';
  from: Nullable<string>;
  to: Nullable<string>;
};

const CashbackReportBlock = () => {
  const [isGenerating, setIsGenerating] = useState(false);
  const { t } = useTranslate();
  const client = useClient();
  const [{ data }] = useQuery<CashbackReportBox>({
    query: QUERY,
  });

  const block = useStaticQuery<SanityCashbackReportBlockStaticQuery>(
    BLOCK_STATIC_QUERY,
  ).sanityCashbackReportBlock;

  const today = useMemo(() => new Date(), []);

  const brandOptions = useMemo(() => {
    const brandOptions: SelectOption[] =
      data?.viewer.brands.map((brand) => ({
        label: brand.name,
        value: brand.code,
      })) ?? [];

    const options: SelectOption[] = [
      {
        label: t(block?.selectBrand),
        value: '',
      },
      ...brandOptions,
    ];

    return options;
  }, [data, block?.selectBrand, t]);

  const defaultValues: FormValues = {
    brand: '',
    from: null,
    to: null,
  };

  const onSubmit = ({ brand, from, to }: FormValues) => {
    if (!brand) {
      return;
    }

    setIsGenerating(true);

    client
      .query<CashbackReport, CashbackReportVariables>(
        REPORT_QUERY,
        {
          brand,
          from: from ?? null,
          to: to ?? null,
        },
        {
          requestPolicy: 'network-only',
        },
      )
      .toPromise()
      .then((res) => {
        const url = res.data?.viewer.exportedCashbackReportV4.url;

        if (url) {
          saveAs(url, 'cashback-report.csv');
        }
      })
      .finally(() => {
        setIsGenerating(false);
      });
  };

  if (!block) {
    return null;
  }

  return (
    <Card size="lg" title={t(block.title)}>
      <CardBody>
        <div className="p-3">
          <Form
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            className="grid gap-3"
          >
            <SelectField
              name="brand"
              id="cashback-report-block__brand"
              title={t(block.brandLabel)}
              options={brandOptions}
              required
            />
            <DateField
              name="from"
              id="cashback-report-block__from"
              title={t(block.fromLabel)}
              maxDate={today}
            />
            <DateField
              name="to"
              id="cashback-report-block__to"
              title={t(block.toLabel)}
              maxDate={today}
            />
            <SubmitButton
              value={t(block.exportCashback)}
              disabled={isGenerating}
            />
          </Form>
        </div>
      </CardBody>
    </Card>
  );
};

export default CashbackReportBlock;
